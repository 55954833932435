<template>
  <LayoutMainPages>
    <div class="flex justify-center items-center -mt-s32 start-xl:-mt-s48">
      <div class="container">
        <RewardTopBackground/>

        <RewardHeader
          @onRefreshRewardsState="getRewardsState"
          :balance="balance"
          :canClaimDayNumber="canClaimDayNumber"
          :isComponentLoading="isRewardsStateLoading"
          :neverClaimedBefore="neverClaimedBefore"
          :nextUpcomingDayNumber="nextUpcomingDayNumber"
          :nextClaimStart="nextClaimStart"
          :nextClaimEnd="nextClaimEnd"
        />

        <RewardClaimDayList
          @onDateClick="claimReward"
          class="mt-s40"
          :currentClaimDay="currentClaimDay"
          :days="days"
          :hasReward="hasReward"
          :isComponentLoading="isRewardsStateLoading"
          :isKycComplete="isKycComplete"
          :isLoading="isLoading"
        />

        <RewardShopList
          @onBuyReward="handleBuyReward"
          class="mt-s40"
          :balance="balance"
          :isComponentLoading="isRewardsShopListLoading"
          :shopList="shopItems"
        />

        <RewardDisclaimer
          class="mt-s32"
          @onShowRewardDisclaimer="handleShowRewardDisclaimer"
          :isComponentLoading="isRewardsShopListLoading"
        />
      </div>
    </div>
  </LayoutMainPages>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import { LayoutMainPages } from '@/components/misc';
import {
  RewardClaimDayList,
  RewardDisclaimer,
  RewardHeader,
  RewardShopList,
  RewardTopBackground,
} from '@/modules/rewards';

export default {
  name: 'Rewards',

  components: {
    LayoutMainPages,
    RewardClaimDayList,
    RewardDisclaimer,
    RewardHeader,
    RewardShopList,
    RewardTopBackground,
  },

  data() {
    return {
      isLoading: false,
    };
  },

  async mounted() {
    await this.getRewardsShopItems();
    await this.$store.dispatch('events/track', {
      event: 'LOYALTY_PAGE_VIEWED',
    });
  },

  computed: {
    ...mapGetters('user', [
      'isKycComplete',
    ]),

    ...mapState('rewards', [
      'actionClaims',
      'balance',
      'currentClaimDay',
      'days',
      'disclaimer',
      'hasReward',
      'history',
      'isKycLiteComplete',
      'isRewardsActionClaimsLoading',
      'isRewardsHistoryLoading',
      'isRewardsShopListLoading',
      'isRewardsStateLoading',
      'neverClaimedBefore',
      'nextClaimStart',
      'nextClaimEnd',
      'shopItems',
    ]),

    canClaimDayNumber() {
      return this.days.findIndex(day => day.state === 'can_be_claimed') + 1;
    },

    nextUpcomingDayNumber() {
      return this.days.findIndex(day => day.state === 'upcoming') + 1;
    },
  },

  methods: {
    ...mapActions('rewards', [
      'dailyRewardClaim',
      'getRewardsActionClaims',
      'getRewardsHistory',
      'getRewardsShopItems',
      'getRewardsState',
    ]),
    ...mapActions('user', ['requiresKycComplete']),

    async claimReward() {
      try {
        this.isLoading = true;

        await this.requiresKycComplete();

        await this.dailyRewardClaim();
      } catch (err) {
        await this.$store.dispatch('ui/showError', err);

        if (err['error_code'] === 'rewards_already_claimed') {
          await this.getRewardsState();
        }
      }
      finally {
        this.isLoading = false;
      }
    },

    handleBuyReward(shopItem) {
      this.showModal('RewardReviewOrder', {
        ...shopItem,
      });
    },

    handleShowRewardDisclaimer() {
      this.showModal('RewardDisclaimer', { disclaimer: this.disclaimer, isClosable: false, });
    },
  },
};
</script>

<style scoped>
  .container {
    @apply flex flex-col justify-center items-center;
    max-width: 791px;
  }
</style>
